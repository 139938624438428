const React = require('react');
const { ContextSettingsProvider } = require('./src/contexts/context-settings');

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <ContextSettingsProvider>
    {element}
  </ContextSettingsProvider>

);
